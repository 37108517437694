export default ($axios) => ({
  findRodovias(siglaEmpresa, search) {
    if (siglaEmpresa) {
      if (search === null || search === undefined) {
        return $axios.get(`/rodovias/${siglaEmpresa}/all`)
      } else {
        return $axios.get(`/rodovias/${siglaEmpresa}/all?search=${search}`)
      }
    } else {
      return $axios.get(`/rodovias/all`)
    }
  },

  getRodoviaById(idRodovia) {
    return $axios.get(`/rodovias/${idRodovia}`)
  },

  saveRodovia(siglaEmpresa, rodovia) {
    $axios.setHeader('Content-Type', 'application/json')
    return $axios.post(`/rodovias/${siglaEmpresa}`, rodovia)
  },

  deleteRodovia(siglaEmpresa, idRodovia) {
    return $axios.delete(`/rodovias/${siglaEmpresa}/${idRodovia}`)
  },

  findTrechos(siglaEmpresa) {
    return $axios.get(`/rodovias/${siglaEmpresa}/trechos/all`)
  },

  findTrechosByRodovia(siglaEmpresa, rodovia) {
    return $axios.get(`/rodovias/${siglaEmpresa}/${rodovia}/trechos`)
  },

  getTrechoById(siglaEmpresa, idTrecho) {
    return $axios.get(`/rodovias/${siglaEmpresa}/trechos/${idTrecho}`)
  },

  saveTrecho(siglaEmpresa, trecho) {
    return $axios.post(`/rodovias/${siglaEmpresa}/trechos`, trecho)
  },

  deleteTrecho(idTrecho) {
    return $axios.delete(`/rodovias/trechos/${idTrecho}`)
  },

  getSegmentosByRodovia(siglaEmpresa, idRodovia) {
    return $axios.get(`/rodovias/${siglaEmpresa}/${idRodovia}/segmentos`)
  },

  getSegmentosByTrecho(siglaEmpresa, idRodovia, idTrecho) {
    return $axios.get(
      `/rodovias/${siglaEmpresa}/${idRodovia}/trechos/${idTrecho}/segmentos`
    )
  },

  findCoordenadas(siglaEmpresa, rodovia) {
    return $axios.get(`/rodovias/${siglaEmpresa}/${rodovia}/coordenadas`)
  },

  saveIndicadores(indicadores) {
    return $axios.post(`/rodovias//indicadores/refresh`, indicadores)
  },

  findSths(siglaEmpresa) {
    return $axios.get(`/rodovias/${siglaEmpresa}/sths`)
  },

  getSthById(siglaEmpresa, idSth) {
    return $axios.get(`/rodovias/${siglaEmpresa}/sths/${idSth}`)
  },

  saveSth(siglaEmpresa, sth) {
    return $axios.post(`/rodovias/${siglaEmpresa}/sths`, sth)
  },

  deleteSth(siglaEmpresa, idSth) {
    return $axios.delete(`/rodovias/${siglaEmpresa}/sths/${idSth}`)
  },

  getRodoviaEquivalenteByIdRodovia(idRodovia) {
    return $axios.get(`/rodovias/${idRodovia}/anosConcessao`)
  }
})
