export default ($axios) => ({
  find() {
    return $axios.get('/empresas')
  },

  findAll(search) {
    if (search === null || search === undefined) {
      return $axios.get('/empresas/all')
    } else {
      return $axios.get(`/empresas/all?search=${search}`)
    }
  },

  findEmpresaByToken() {
    return $axios.get('/empresas')
  },

  getBySigla(sigla) {
    return $axios.get(`/empresas/${sigla}`)
  },

  getLogomarcas() {
    return $axios.get(`/downloads/logomarcas`)
  },

  getBackgrounds() {
    $axios.setHeader('Content-Type', 'application/json')
    const requestCfg = {
      responseType: 'arraybuffer',
    }
    return $axios.get(`/downloads/backgrounds`, requestCfg)
  },

  findAreas(empresa) {
    return $axios.get(`/empresas/${empresa}/areas`)
  },

  findAreaById(idArea) {
    return $axios.get(`/empresas/areas/${idArea}`)
  },

  saveArea(siglaEmpresa, area) {
    return $axios.post(`/empresas/${siglaEmpresa}/areas`, area)
  },

  deleteArea(siglaEmpresa, idArea) {
    return $axios.delete(`/empresas/${siglaEmpresa}/areas/${idArea}`)
  },

  findResponsaveisArea(empresa, idArea) {
    return $axios.get(`/empresas/${empresa}/${idArea}/responsaveisArea`)
  },

  findUsuarios(siglaEmpresa) {
    return $axios.get(`/empresas/${siglaEmpresa}/usuarios`)
  },
  findUsuariosAreas(siglaEmpresa) {
    return $axios.get(`/empresas/${siglaEmpresa}/usuariosAreas`)
  },
  saveUsuariosAreas(siglaEmpresa, listaDeUsuarios) {
    return $axios.post(
      `/empresas/${siglaEmpresa}/usuariosAreas`,
      listaDeUsuarios
    )
  },

  deleteUsuarioArea(siglaEmpresa, idUsuario, idArea) {
    return $axios.delete(
      `/empresas/${siglaEmpresa}/usuariosAreas/${idUsuario}/${idArea}`
    )
  },

  findEmails(siglaEmpresa) {
    return $axios.get(`/empresas/${siglaEmpresa}/emails`)
  },
  saveEmail(siglaEmpresa, payload) {
    return $axios.post(`/empresas/${siglaEmpresa}/emails`, payload)
  },
  deleteEmail(siglaEmpresa, idUsuario) {
    return $axios.delete(`/empresas/${siglaEmpresa}/emails/${idUsuario}`)
  },

  save(empresa) {
    return $axios.post(`/empresas`, empresa)
  },

  deleteEmpresa(siglaEmpresa) {
    return $axios.delete(`/empresas/${siglaEmpresa}`)
  },

  findFiliais(siglaEmpresa, idHolding, incluirHolding) {
    const urlHolding = '?incluirHolding=true'

    if (incluirHolding === true) {
      return $axios.get(
        `/empresas/${siglaEmpresa}/filiais/${idHolding}${urlHolding}`
      )
    } else {
      return $axios.get(`/empresas/${siglaEmpresa}/filiais/${idHolding}`)
    }
  },

  findLocais(siglaEmpresa) {
    return $axios.get(`/empresas/${siglaEmpresa}/locais`)
  },

  saveLocal(siglaEmpresa, payload) {
    return $axios.post(`/empresas/${siglaEmpresa}/locais`, payload)
  },

  deleteLocal(siglaEmpresa, idLocal) {
    return $axios.delete(`/empresas/${siglaEmpresa}/local/${idLocal}`)
  },
})
